



















































































import { Component, Mixins, PropSync, VModel } from "vue-property-decorator"
import { UseFields } from "piramis-base-components/src/components/Pi"
import { InputSetups } from "../../mixins/input-setups"

@Component
export default class SetPeriod extends Mixins<UseFields, InputSetups>(UseFields, InputSetups){
  @VModel() periodSettings!: { from: { date: string, time: string}, to: { date: string, time: string }, onlyJoinFirstTime: boolean }
}
