var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col items-end"},[_c('a-alert',{staticClass:"w-full",attrs:{"message":_vm.$t('remove_for_period_dates_alert')}}),_c('div',{staticClass:"flex w-full flex-col"},[_c('div',{staticClass:"w-full md:flex"},[_c('div',{staticClass:"md:w-1/2 w-full"},[_c('date-picker-input',{attrs:{"setup":{
            'func': _vm.modelSetter,
            'args': {
              'model': _vm.periodSettings.from,
              'key': 'date',
              'clearable': false,
              'validation': 'required',
              'prefix': 'remove_for_period_from_'
            }
          }}}),_c('date-picker-input',{attrs:{"setup":{
            'func': _vm.modelSetter,
            'args': {
              'model': _vm.periodSettings.to,
              'key': 'date',
              'clearable': false,
              'validation': 'required',
              'prefix': 'remove_for_period_to_'
            }
          }}})],1),_c('div',{staticClass:"md:w-1/2 w-full"},[_c('time-picker-input',{attrs:{"setup":{
            'func': _vm.modelSetter,
            'args': {
              'model': _vm.periodSettings.from,
              'key': 'time',
              'time24hr': true,
              'clearable': false,
              'validation': 'required',
              'disabled': !_vm.periodSettings.from.date,
              'prefix': 'remove_for_period_from_'
            },
          }}}),_c('time-picker-input',{attrs:{"setup":{
            'func': _vm.modelSetter,
            'args': {
              'model': _vm.periodSettings.to,
              'key': 'time',
              'time24hr': true,
              'clearable': false,
              'validation': 'required',
              'disabled': !_vm.periodSettings.to.date,
              'prefix': 'remove_for_period_to_'
            },
          }}})],1)]),_c('div',{staticClass:"w-full"},[_c('switch-input',{attrs:{"setup":{
          'func': _vm.modelSetter,
          'args': {
            'model': _vm.periodSettings,
            'key': 'onlyJoinFirstTime',
            'prefix': 'remove_for_period_to_',
          }
        }}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }