
































































































































































































































import PageTitle from "@/components/PageTitle.vue"
import { ChannelService } from "@/includes/services/ChannelService"
import {
  ClearUsersRequest,
  ClearUsersRequestActions,
  ClearUsersTargetTypes,
  RequestStatus,
  TargetType
} from "@/includes/types/Channel.types"
import ClearUsersRequestView from '../../components/RemoveuserFroPeriod/clearUsersRequestView.vue'
import SetPeriod from "@/components/RemoveuserFroPeriod/setPeriod.vue"
import { InviteLinksService } from "@/includes/services/InviteLinksService"
import { errorNotification } from "@/includes/services/NotificationService"
import { InputSetups } from "@/mixins/input-setups";
import RemoveUsersRequestStat from "@/components/RemoveuserFroPeriod/removeUsersRequestStat.vue"
import { GetBoardByIdType } from '@/store/boards/BoardsGettersInterface'

import { UseFields } from "piramis-base-components/src/components/Pi"

import moment from "moment"
import { Component, Mixins } from "vue-property-decorator"
import { mapGetters } from 'vuex'

@Component({
  components: {
    PageTitle,
    SetPeriod,
    RemoveUsersRequestStat,
    ClearUsersRequestView,
  },
  data() {
    return {
      TargetType,
      RequestStatus
    }
  },
  computed: {
    ...mapGetters([ 'getBoardById' ])
  }
})
export default class ChannelProtection extends Mixins<UseFields, InputSetups>(UseFields, InputSetups) {
  getBoardById!:GetBoardByIdType

  periodSettings = {
    from: {
      date: '',
      time: '',
    },
    to: {
      date: '',
      time: '',
    },
    onlyJoinFirstTime: false
  }

  confirmRequestModalIsOpen = false

  createRequestModalIsOpen = false

  activeRequests: Array<ClearUsersRequest> = []

  requestTargetKey = ''

  inviteLinks: Array<{label: string, value: string}> = []

  currentLinkCode = ''

  TGLink = ''

  createdRequest: ClearUsersRequest | null = null

  currentRequest: ClearUsersRequest | null = null

  requestStarted = false

  get activeRequestIsExists(): boolean {
    return !this.activeRequests.some((request) => [ RequestStatus.Process, RequestStatus.Wait ].includes(request.status))
  }

  closeConfirmRequestModal(): void {
    this.confirmRequestModalIsOpen = false
    this.currentRequest = null
  }

  saveChannelConfig(): void {
    this.$store.dispatch('save_active_channel_config', this.$route)
  }

  showNotifyBeforeRemoving(requestKey: string): void {
    const foundRequest = this.activeRequests.find((request) => request.key === requestKey)

    if (foundRequest) {
      this.currentRequest = foundRequest
      this.confirmRequestModalIsOpen = true
    }
  }

  applyHandler(): void {
    if (this.createdRequest) {
      this.closeCreateModal()
    } else {
      this.createRequest()
    }
  }

  setStatus({ key, status }: {key: string, status: RequestStatus}): void {
    const request = this.activeRequests.find((request) => request.key === key)
    if (request) {
      request.status = status
    }
  }

  setStats(newRequest: any): void {
    const index = this.activeRequests.findIndex((request) => request.key === newRequest.key)
    this.activeRequests.splice(index, 1, newRequest)
  }

  startRemoving(request: any): void {
    if (!this.requestStarted) {
      this.requestStarted = true
      ChannelService.clearUsersRequest('tg', { action: ClearUsersRequestActions.Confirm, key: request.key, users: request.users as number })
        .then((res) => {
          if (!('items' in res)) {
            request.status = res.status
            return res.status
          }
        })
        .then((status) => {
          if (status === RequestStatus.Started) {
            ChannelService.clearUsersRequest('tg', { action: ClearUsersRequestActions.Status, key: request.key })
              .then((res) => {
                if (!('items' in res)) {
                  request.status = res.status
                }
              })
          }
        })
        .finally(() => {
          this.confirmRequestModalIsOpen = false
          this.requestStarted = false
        })
    }
  }

  cancelRequest(requestKey: string): void {
    const request = this.activeRequests.find((request) => request.key === requestKey)

    if (request) {
      ChannelService.clearUsersRequest('tg', {
        board_key: this.getBoardById(this.$route.params.id)!.board,
        action: ClearUsersRequestActions.Cancel,
        key: request.key
      })
        .then((res) => {
          if (!('items' in res) && res.status === RequestStatus.Stop) {
            request.status = RequestStatus.Stop
          }
        })
        .finally(() => {
          this.confirmRequestModalIsOpen = false
        })
    }
  }

  removeCreateRequestSettings(): void {
    this.requestTargetKey = ''
    this.currentLinkCode = ''
    this.createdRequest = null
    this.TGLink = ''
  }

  closeCreateModal(): void {
    this.createRequestModalIsOpen = false
    this.removeCreateRequestSettings()
  }

  get checkRequestData(): boolean {
    const from = this.periodSettings.from
    const to = this.periodSettings.to
    const dateOk = from.date && from.time && to.date && to.time && moment(`${ from.date } ${ from.time }`).isBefore(moment(`${ to.date } ${ to.time }`)) && moment(from.date).isAfter(moment().startOf('day').add(-14, 'd'))

    if (dateOk) {
      if (this.requestTargetKey === TargetType.Channel) {
        return false
      } else if (this.requestTargetKey === TargetType.Press) {
        return !this.currentLinkCode
      } else {
        return !this.TGLink
      }
    } else {
      return true
    }
  }

  createRequest(): void {
    const from = this.periodSettings.from
    const to = this.periodSettings.to

    if (this.requestTargetKey === TargetType.Channel) {
      this.createReq({ type: TargetType.Channel, channel_id: this.$route.params.channelId }, from, to)
    } else if (this.requestTargetKey === TargetType.Press) {
      this.createReq({ type: TargetType.Press, code: this.currentLinkCode, channel_id: this.$route.params.channelId }, from, to)
    } else {
      this.createReq({ type: TargetType.TG, link: this.TGLink, channel_id: this.$route.params.channelId }, from, to)
    }
  }

  createReq(target: ClearUsersTargetTypes, from, to): void {
    ChannelService.clearUsersRequest('tg', {
      action: ClearUsersRequestActions.Create,
      board_key: this.getBoardById(this.$route.params.id)!.board,
      target,
      only_join_first_time: this.periodSettings.onlyJoinFirstTime,
      from: `${ from.date } ${ from.time }`,
      to: `${ to.date } ${ to.time }`
    })
      .then((res: any) => {
        this.createdRequest = res
        this.activeRequests.unshift(res)
      })
  }

  async created(): Promise<void> {
    if (!this.$store.state.inviteLinksState.links) {
      await this.$store.dispatch('getInviteLinks')
    }

    InviteLinksService.getInviteLinks('tg', {
      board_key: this.getBoardById(this.$route.params.id)!.board,
      target_id: Number.parseInt(this.$route.params.channelId)
    })
      .then((data) => this.inviteLinks = data.links.map((link) => {
        return {
          label: link.title,
          value: link.key,
        }
      }))
      .finally(() => {
        if (this.$route.query && this.$route.query.pressLink) {
          this.createRequestModalIsOpen = true
          this.requestTargetKey = TargetType.Press
          this.currentLinkCode = this.$route.query.pressLink as string
        }
      })
      .catch((err: any) => {
        errorNotification(err)
      })

    ChannelService.clearUsersRequest('tg', { action: ClearUsersRequestActions.Active, board_key: this.getBoardById(this.$route.params.id)!.board })
      .then((res) => {
        if ('items' in res) {
          const items = res.items
          const from = this.periodSettings.from
          const to = this.periodSettings.to

          from.date = moment().format('YYYY-MM-DD')
          from.time = '12:00:00'

          to.date = moment().add(1, 'd').format('YYYY-MM-DD')
          to.time = '12:00:00'

          if (items.length) {
            this.activeRequests = items.map((request) => {
              let id = 0
              if (request.status === RequestStatus.Wait) {
                id = 1
              } else if (request.status === RequestStatus.Process) {
                id = 2
              } else if (request.status === RequestStatus.Complete) {
                id = 3
              } else if (request.status === RequestStatus.Stop) {
                id = 4
              }

              return {
                ...request,
                statusId: id
              }
            }).sort((a, b) => a.statusId - b.statusId)
          }
        }
      })
      .catch((error) => {
        errorNotification(error)
        this.$emit('getActiveLinksError', error)
      })
  }

  destroyed(): void {
    this.$store.dispatch('AdminTemplate/hideSaveConfigButton')
  }

  mounted(): void {
    this.$store.dispatch('AdminTemplate/showSaveConfigButton')
  }
}
